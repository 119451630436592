<template>
<v-container fluid fill-height >
    <v-row no-gutters align-content="center" justify="center">
        <v-col sm="6" cols="12" md="3" xl="3">
            <v-card class="pa-2 rounded-lg card-login" flat v-if="show">
                <v-img src="../../public/img/icons/android-chrome-512x512.png" max-width="140" contain class="mx-auto text-center img-login" />
                <p class="text-center font-weight-medium ma-0 trenos-title grey--text text--darken-2">Trenos</p>
                <transition name="fade" mode="out-in">
                    <div v-if="vista == 'Login'" key="1">
                        <v-list-item-subtitle class="font-weight-medium text-center grey--text text--darken-2">
                            <span class=" text-body-1 text-capitalize">Acceso</span>
                        </v-list-item-subtitle>
                        <v-card-text class="py-1 px-3">
                            <v-container>
                                <v-row>
                                    <v-col cols="12" class="py-0">
                                        <v-text-field label="Ingresa tu rut" outlined dense prepend-icon="mdi-account-circle-outline" v-on:keyup="FormatRut" v-model="login.rut" maxlength="12" autocomplete="off" v-on:keyup.enter=" CheckLogin ? LoginServer(): null" hide-details clearable/>
                                    </v-col>
                                    <v-col cols="12" class="pb-2">
                                        <v-text-field label="Ingresa tu contraseña" outlined dense prepend-icon="mdi-lock-outline" v-model="login.clave" type="password" autocomplete="off" v-on:keyup.enter=" CheckLogin ? LoginServer(): null" hide-details  clearable/>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions class="mx-4">
                            <v-btn block depressed color="primary" :disabled="!CheckLogin" @click="LoginServer()" :loading="login.login_loading">Ingresar</v-btn>
                        </v-card-actions>
                        <v-card-actions class="mx-4">
                            <v-btn block small text class="grey--text  text--darken-1" @click="GoRecovery">Olvidaste tu contraseña?</v-btn>
                        </v-card-actions>
                    </div>
                    <div v-if="vista == 'Recovery'" key="2">
                        <v-list-item-subtitle class="font-weight-medium text-center grey--text text--darken-2">
                            <span class=" text-body-1 text-capitalize">Recuperar Contraseña</span>
                        </v-list-item-subtitle>
                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col cols="12" class="py-0">
                                        <v-text-field label="Ingresa tu rut" outlined dense prepend-icon="mdi-account-circle-outline" v-on:keyup="FormatRut" v-model="login.rut" maxlength="12" autocomplete="off" hide-details clearable/>
                                    </v-col>
                                    <v-col cols="12" class="pb-2">
                                        <v-text-field label="Ingresa tu correo" outlined dense prepend-icon="mdi-at" v-model="login.correo" type="email" autocomplete="off" hide-details clearable/>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions class="mx-4 py-0">
                            <v-btn block depressed color="primary" :disabled="!CheckRecovery" @click="Recovery()" :loading="login.recovery_loading">recuperar</v-btn>
                        </v-card-actions>
                        <v-card-actions>
                            <v-btn small block depressed text color="grey darken-1" @click="GoLogin()">Volver</v-btn>
                        </v-card-actions>
                    </div>
                </transition>
            </v-card>
        </v-col>
    </v-row>
</v-container>
</template>

<script>
import { mapMutations, mapState} from 'vuex'
export default {
    name: 'Login',
    data() {
        return {
            vista: 'Login',
            login: {
                rut: null,
                clave: null,
                correo: null,
                login_loading: false,
                recovery_loading: false
            },
            show: true
        }
    },
    computed: {
        ...mapState(['componente', 'sistema']),
        CheckLogin() {
            if (this.login.rut && this.login.clave) {
                return true
            } else {
                return false
            }
        },
        CheckRecovery() {
            if (this.login.rut && this.login.correo) {
                return true
            } else {
                return false
            }
        }

    },
    methods: {
        ...mapMutations(['ShowSnackBar', 'UserView', 'ErrorRequest']),
        GoRecovery() { //Muestra la ventana para el recovery de la clave
            this.login.correo = null
            this.vista = 'Recovery'
        },
        GoLogin() { //Muestra la ventana para el login al sistema.
            this.login.rut = null
            this.login.clave = null
            this.vista = 'Login'
        },
        FormatRut() { //Formatea el rut al ingresar los numeros.
            let clear_rut = typeof this.login.rut === 'string' ? this.login.rut.replace(/[^0-9kK]+/g, '').toUpperCase() : '' // limpiamos la variable rut
            if (clear_rut.length <= 1) {
                return false
            }
            var result = clear_rut.slice(-4, -1) + '-' + clear_rut.substr(clear_rut.length - 1)
            for (var i = 4; i < clear_rut.length; i += 3) {
                result = clear_rut.slice(-3 - i, -i) + '.' + result
            }
            this.login.rut = result
            let rut = this.login.rut
            if (typeof rut !== 'string') {
                return false
            }
            rut = typeof this.login.rut === 'string' ? this.login.rut.replace(/[^0-9kK]+/g, '').toUpperCase() : ''
            var rutDigits = parseInt(rut.slice(0, -1), 10)
            var m = 0
            var s = 1
            while (rutDigits > 0) {
                s = (s + rutDigits % 10 * (9 - m++ % 6)) % 11
                rutDigits = Math.floor(rutDigits / 10)
            }
        },
        //Valida los datos con el servidor
        async LoginServer() { 
            this.login.login_loading = true
            await this.axios.post('/login', {
                usuario: this.login.rut,
                clave: this.login.clave
            }).then(respuesta => {
                if (respuesta.data.mensaje) {
                    this.ErrorRequest(respuesta.data.mensaje)
                    this.login.login_loading =   false
                } else {
                    let app_data = {
                        nombre: respuesta.data.usuario_datos.nombre,
                        tipo: respuesta.data.usuario_datos.turno_tipo,
                        token: respuesta.data.token,
                        app_version: this.sistema.app_version,
                        tema: 'light'
                    }
                    localStorage.setItem("app_data", JSON.stringify(app_data))
                    this.componente.principal = 'Home'
                    this.componente.secundario = null
                    this.UserView()
                    this.login.login_loading = false
                }
            }).catch(err => {
                this.ErrorRequest(err)
                this.login.login_loading = false
            });
        },
        async Recovery() { //Envia los datos al servidor para recueprar la contraseña (generacion)
            this.login.recovery_loading = true
            await this.axios.post('/login/recovery', {
                usuario: this.login.rut,
                correo: this.login.correo
            }).then(respuesta => {
                if (respuesta.data.mensaje) {
                    this.ErrorRequest(respuesta.data.mensaje)
                    if (respuesta.data.mensaje.code == 0) {
                        this.login.recovery_loading = false
                        this.GoLogin()
                    } else {
                        this.login.recovery_loading = false
                    }
                }
            }).catch(err => {
                this.ErrorRequest(err)
                this.login.recovery_loading = false
            })
        }
    }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity .2s
}

.fade-enter,
.fade-leave-to {
    opacity: 0
}

.trenos-title {
    font-family: 'Product Sans', 'Roboto' !important;
    font-size: 2.5rem;
}

.img-login {
    margin-top: -4rem;
}

.card-login{
    z-index: 10;
}
</style>
